<template>
  <van-tabbar
    active-color="#DF3D48"
    inactive-color="#bdbdbf"
    :z-index="9999"
    v-model="actives"
    @change="init"
  >
    <van-tabbar-item icon="wap-home" to="user">
      <span>首页</span>
      <template #icon="props">
        <img class="iosd" :src="props.active ? icon.active : icon.inactive" />
      </template>
    </van-tabbar-item>
    <!-- v-if="partnerLevel == 0"  -->
    <van-tabbar-item @click.native="ccd" to="mine" replace icon="gift-o" >
      <span>兑换</span>
      <template #icon="props">
        <img class="iosd" :src="props.active ? icon6.active : icon6.inactive" />
      </template>
    </van-tabbar-item>
    <!-- <van-tabbar-item v-else @click="tochaxun" replace icon="gift-o">
      <span>查询</span>
      <template #icon="props">
        <img class="iosd" :src="props.active ? icon2.active : icon2.inactive" />
      </template>
    </van-tabbar-item> -->
    <van-tabbar-item replace icon="todo-list-o" @click.native="toerweima">
      <span>专属码</span>
      <template #icon="props">
        <img class="iosd" :src="props.active ? icon3.active : icon3.inactive" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace icon="shop" @click.native="toshop">
      <span>门店</span>
      <template #icon="props">
        <img class="iosd" :src="props.active ? icon4.active : icon4.inactive" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item icon="manager" @click.native="tomine">
      <span>我的</span>
      <template #icon="props">
        <img class="iosd" :src="props.active ? icon5.active : icon5.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import Bus from "./bus.js";
import orderModel from "../api/order";
export default {
  name: "tabbar",
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      actives: 0,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      partnerLevel: sessionStorage.getItem("partnerLevel"),
      icon: {
        active: require("../assets/img/ssyy2.png"),
        inactive: require("../assets/img/ssyy.png")
      },
      icon2: {
        active: require("../assets/img/duihuan2.png"),
        inactive: require("../assets/img/duihuan.png")
      },
      icon3: {
        active: require("../assets/img/baoliao2.png"),
        inactive: require("../assets/img/baoliao.png")
      },
      icon4: {
        active: require("../assets/img/md2.png"),
        inactive: require("../assets/img/md.png")
      },
      icon5: {
        active: require("../assets/img/wd2.png"),
        inactive: require("../assets/img/wd.png")
      },
      icon6: {
        active: require("../assets/img/duima2.png"),
        inactive: require("../assets/img/duima.png")
      },
      img: require("../assets/img/ssyy.png")
    };
  },
  created() {},
  mounted() {
    /**
     * 判断当前页面是哪个页面,解决刷新时,tab默认为 1 的bug
     */
    // else if (CURRENTHREF.includes('MinShopCate')) {
    //   this.actives = 1
    // }
    // console.log(this.$route.path);
    if (this.$route.path == "/user") {
      this.actives = 0;
    } else if (this.$route.path == "/inquire") {
      this.actives = 1;
    } else if (this.$route.path == "/exclusive") {
      this.actives = 2;
    } else if (this.$route.path == "/shop") {
      this.actives = 3;
    } else if (this.$route.path == "/mine") {
      this.actives = 4;
    }
    // if (this.shangjia.id) {
    //   this.Consumer_partner();
    // }
   
  },
  watch: {
    $route() {
      this.init(this.active);
    }
  },
  methods: {
    //    //跳转我的
    tomine() {
      if (this.$route.path != "/mine") {
        window.location.href = "/mine";
      }
    },
    tochaxun() {
      this.$router.push({
        path: "inquire"
      });
    },
    init(index) {
      if (index == 1) {
      } else {
        //
        this.actives = index;
      }
    },
    toerweima() {
      console.log(this.$route.path);
      var shangjia = JSON.parse(sessionStorage.getItem("shangjia"));
      if (this.$route.path != "/exclusive") {
        window.location.href =
          "/exclusive?gid=" +
          shangjia.id +
          "&garageName=" +
          shangjia.name +
          "&customerId=" +
          this.userInfo.id;
      }
      // localStorage.setItem("isok", true);
      // var utf = this.shangjia.name
      // var garageName = encodeURIComponent(utf);

      //   @click.native="tomine"
      // this.$router.push({
      //   path: "exclusive",
      //   query: {
      //     gid: this.shangjia.id,
      //     garageName: this.shangjia.name,
      //     customerId: this.userInfo.id
      //   }
      // });
    },
    toshop() {
      var shangjia = JSON.parse(sessionStorage.getItem("shangjia"));
      if (this.shangjia.chain) {
        this.$router.push({
          path: "Store_list",
          query: {
            isok: true
          }
        });
      } else {
        this.$router.push({
          path: "shop",
          query: {
            gid: shangjia.id
          }
        });
      }
    },

    ccd() {
        // this.$router.push({
        //   path: "mine"
        // });
        sessionStorage.setItem('tanisok',true)
      // window.location.href = "/mine";
      Bus.$emit("to-news", "true");
    }
  }
};
</script>
<style lang="less">
.van-tabbar-item__icon {
  font-size: 26px;
}
.van-tabbar-item__icon img {
  width: 24px;
  height: 24px;
}
</style>