
<template>
  <div class="keyboard" style="padding-top: 0;">
    <van-nav-bar title="查询车牌号" :z-index="999" />
    <div class="input-box" @click="clickShowKeyboard">
      <li>{{first}}</li>
      <li>{{numArr[0]}}</li>
      <li>{{numArr[1]}}</li>
      <li>{{numArr[2]}}</li>
      <li>{{numArr[3]}}</li>
      <li>{{numArr[4]}}</li>
      <li>{{numArr[5]}}</li>
      <li>{{numArr[6]}}</li>
    </div>
    <div class="divbots">
      <van-button class="bots" type="primary" @click="tochaxun">查 询</van-button>
    </div>
    <div class="divbotsname">
      <span v-if="nameisok == true" style="color: #1989fa;">该车牌是新客户！</span>
      <span v-if="nameisok == false" style="color: red;">该车牌是老客户！</span>
    </div>
    <!-- 选择车牌号 首个汉字键盘 弹出层 -->
    <div class="plate_number">
      <van-popup
        v-model="show_chinese"
        position="bottom"
        :overlay="true"
        overlay-class="displayNone"
      >
        <div class="plate_chinese_box">
          <!-- 点击对应的汉字，进行输入 -->
          <van-button
            size="small"
            v-for="(item, index) in ChineseList"
            :key="item.id"
            :disabled="item.disabled"
            @click="checkChinese(index)"
          >{{item.name}}</van-button>
          <!-- <div class="close-box" @click.stop="close_keyboard">
            <div>
              <van-button
                type="info"
                size="normal"
                style="width: 100%;height: 100%;box-shadow: none;"
              >确认</van-button>
            </div>
          </div>-->
        </div>
      </van-popup>
    </div>
    <!-- 英文 数字 键盘 -->
    <div class="allBoard">
      <van-popup
        v-model="show_allBoard"
        position="bottom"
        :overlay="true"
        style="bottom:55px"
        overlay-class="displayNone"
      >
        <div class="plate_number_box">
          <!-- 点击对应的字母或数字，进行输入 -->
          <van-button
            size="small"
            v-for="(item, index) in English_Number"
            :key="item.id"
            :disabled="item.disabled"
            @click="checkEnglish_num(index)"
          >{{item.name}}</van-button>
          <!-- <div class="close-box" @click.stop="close_keyboard">
            <div>
              <van-button
                type="info"
                size="normal"
                style="width: 100%;height: 100%;box-shadow: none;"
              >确认</van-button>
            </div>
          </div>-->
        </div>
      </van-popup>
    </div>
    <tabbar :active="1"></tabbar>
  </div>
</template>
<script>
import tabbar from "../../components/tabbar.vue";
import orderModel from "../../api/order";

export default {
  components: {
    tabbar
  },
  props: {
    plate_number: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showKeyboard: true, //车牌号输入框是否聚焦
      show_chinese: true, //是否显示汉字键盘
      nameisok: null,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      show_allBoard: false, //是否显示英文数字键盘
      ChineseList: [
        { name: "京", id: 1 },
        { name: "津", id: 2 },
        { name: "冀", id: 3 },
        { name: "晋", id: 4 },
        { name: "蒙", id: 5 },
        { name: "辽", id: 6 },
        { name: "吉", id: 7 },
        { name: "黑", id: 8 },
        { name: "沪", id: 9 },
        { name: "苏", id: 10 },
        { name: "浙", id: 11 },
        { name: "皖", id: 12 },
        { name: "闽", id: 13 },
        { name: "赣", id: 14 },
        { name: "鲁", id: 15 },
        { name: "豫", id: 16 },
        { name: "鄂", id: 17 },
        { name: "湘", id: 18 },
        { name: "粤", id: 19 },
        { name: "桂", id: 20 },
        { name: "琼", id: 21 },
        { name: "渝", id: 22 },
        { name: "川", id: 23 },
        { name: "贵", id: 24 },
        { name: "云", id: 25 },
        { name: "藏", id: 26 },
        { name: "陕", id: 27 },
        { name: "甘", id: 28 },
        { name: "青", id: 29 },
        { name: "宁", id: 30 },
        { name: "新", id: 31 },
        { name: "←", id: 99 }
      ],
      English_Number: [
        { name: "1", id: 28, disabled: true },
        { name: "2", id: 29, disabled: true },
        { name: "3", id: 30, disabled: true },
        { name: "4", id: 31, disabled: true },
        { name: "5", id: 32, disabled: true },
        { name: "6", id: 33, disabled: true },
        { name: "7", id: 34, disabled: true },
        { name: "8", id: 35, disabled: true },
        { name: "9", id: 36, disabled: true },
        { name: "0", id: 37, disabled: true },
        { name: "Q", id: 38, disabled: false },
        { name: "W", id: 39, disabled: false },
        { name: "E", id: 40, disabled: false },
        { name: "R", id: 41, disabled: false },
        { name: "T", id: 42, disabled: false },
        { name: "Y", id: 43, disabled: false },
        { name: "U", id: 44, disabled: false },
        { name: "I", id: 45, disabled: true },
        { name: "O", id: 46, disabled: false },
        { name: "P", id: 47, disabled: false },
        { name: "A", id: 48, disabled: false },
        { name: "S", id: 49, disabled: false },
        { name: "D", id: 50, disabled: false },
        { name: "F", id: 51, disabled: false },
        { name: "G", id: 52, disabled: false },
        { name: "H", id: 53, disabled: false },
        { name: "J", id: 54, disabled: false },
        { name: "K", id: 55, disabled: false },
        { name: "L", id: 56, disabled: false },
        { name: "Z", id: 57, disabled: false },
        { name: "X", id: 58, disabled: false },
        { name: "C", id: 59, disabled: false },
        { name: "V", id: 60, disabled: false },
        { name: "B", id: 61, disabled: false },
        { name: "N", id: 62, disabled: false },
        { name: "M", id: 63, disabled: false },
        { name: "←", id: 99, disabled: false }
      ],
      first: "",
      numArr: []
    };
  },
  watch: {
    "numArr.length": {
      handler(newValue) {
        let num = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        if (newValue === 0) {
          this.English_Number.forEach(v => {
            if (num.includes(v.name) || v.name === "I") {
              this.$set(v, "disabled", true);
            } else {
              this.$set(v, "disabled", false);
            }
          });
        } else if (newValue === 6) {
          let z = ["D", "F", "←", ...num];
          this.English_Number.forEach(v => {
            if (z.includes(v.name)) {
              this.$set(v, "disabled", false);
            } else {
              this.$set(v, "disabled", true);
            }
          });
        } else {
          this.English_Number.forEach(v => {
            if (v.name === "I" || v.name === "O") {
              this.$set(v, "disabled", true);
            } else {
              this.$set(v, "disabled", false);
            }
          });
        }
      }
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 唤起键盘
    clickShowKeyboard() {
      if (this.plate_number) {
        let arr = this.plate_number.split("");
        this.first = arr[0];
        this.numArr = arr.slice(1);
      }

      if (!this.first) {
        this.show_chinese = true;
      } else {
        this.show_chinese = false;
        this.show_allBoard = true;
      }
    },
    // 选择车牌号前面的汉字
    checkChinese(index) {
      // 如果点击删除键，删除第一个格的内容
      if (this.ChineseList[index].id == 99) {
        if (this.nameisok != null) {
          this.nameisok = null;
        }
        this.first = "";
      } else {
        // 把选中的字赋值给第一个格，并且切换键盘
        this.first = this.ChineseList[index].name;
        this.show_chinese = false;
        this.show_allBoard = true;
      }
    },
    // 选择车牌号后面的数字和字母
    checkEnglish_num(index) {
      // 如果点击删除键，删除 numArr 的最后一个值
      if (this.English_Number[index].id == 99) {
            if (this.nameisok != null) {
          this.nameisok = null;
        }
        this.numArr.pop();
        // 如果 numArr 里面被删的没有值了，切换键盘
        if (this.numArr.length == 0) {
          this.show_chinese = true;
          this.show_allBoard = false;
        }
      } else {
        // 把选中的值 push 到 numArr 内
        this.numArr.push(this.English_Number[index].name);
        // 如果 numArr 中的值超过 7 个（车牌号的最大位数），删除最后一个
        if (this.numArr.length > 7) {
          this.numArr.pop();
        }
      }
    },
    // 关闭虚拟键盘
    close_keyboard() {
      if (this.numArr.length < 6) {
        return this.$toast("请输入完整的车牌号码");
      }
      this.show_chinese = false;
      this.show_allBoard = false;
      this.$emit("getPlate", this.first + this.numArr.join(""));
    },
    tochaxun() {
      if (this.numArr.length < 6) {
        return this.$toast("请输入完整的车牌号码");
      }
      const data = {
        carNo: this.first + this.numArr.join(""),
        gid: this.shangjia.id
      };
      orderModel
        .newold(data)
        .then(e => {
          if (e.data == true) {
            this.nameisok = true;
          } else {
            this.nameisok = false;
          }
        })
        .catch();
      console.log(this.first + this.numArr.join(""));
    }
  }
};
</script>

<style scoped lang="less">
.keyboard {
  width: 100%;
  position: absolute;
}
.divbots {
  text-align: center;
}
.divbotsname {
  text-align: center;
  margin-top: 25px;
  font-size: 22px;
}
.bots {
  width: 120px;
}
// 车牌号 & 虚拟键盘
.input-box {
  width: 100%;
  height: 60px;
  margin: auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 6px 8px 0px rgba(96, 100, 112, 0.1);
  border-radius: 0.4rem;
  border: 1px solid rgba(206, 208, 210, 1);
  margin: 0 auto 1.5rem;
  display: flex;
  justify-content: center;
  li {
    flex: 1;
    border-right: 1px solid rgba(206, 208, 210, 1);
    box-sizing: border-box;
    margin-left: -1px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #323233;
  }
  li:last-child {
    border: none;
  }
}

.class-close-box {
  width: 2rem;
  height: 1.5rem;
  position: absolute;
  right: 0.5rem;
  top: -1.3rem;
}
.class-close-box-div {
  width: 1rem;
  height: 1rem;

  font-size: 0.8rem;
  line-height: 1rem;
  margin: auto;
  text-align: center;
  border: 1px solid #666;
  border-radius: 50%;
}
.class-close-box-li {
  width: 1px;
  height: 0.5rem;
  background: #666;
  margin: auto;
}
.overflow-y {
  overflow-y: inherit;
}
.class-van-button-small {
  min-width: 0;
  border-radius: 5px;
  margin: 5px 2px;
  box-shadow: 5px 5px 5px #aaa;
}
.class-plate-box {
  width: 100%;
  padding: 0.7rem 0.5rem;
  box-sizing: border-box;
  background: #eaf1f9;
  position: relative;
}
.plate_number {
  .van-popup {
    overflow-y: inherit;
  }
  .van-popup--bottom {
    background: #eee;
    bottom: 55px;
  }
  .plate_chinese_box {
    width: 100%;
    padding: 0.7rem 0.5rem;
    box-sizing: border-box;
    background: #eaf1f9;
    position: relative;
    font-size: 16px;
    .close-box {
      position: absolute;
      right: 0.3rem;
      top: -30px;
      div {
        font-size: 16px;
        line-height: 16px;
        margin: auto;
        text-align: center;
      }
      li {
        width: 1px;
        height: 0.5rem;
        background: #666;
        margin: auto;
      }
    }
    .van-button--small {
      width: 11.3%;
      height: 50px;
      min-width: 0;
      border-radius: 5px;
      margin: 5px 2px;
      box-shadow: 5px 5px 5px #aaa;
    }
  }
}
.allBoard {
  .van-popup {
    overflow-y: inherit;
  }
  .plate_number_box {
    width: 100%;
    padding: 0.7rem 0.5rem;
    box-sizing: border-box;
    background: #eaf1f9;
    position: relative;
    .close-box {
      position: absolute;
      right: 0.3rem;
      top: -30px;
      div {
        font-size: 16px;
        line-height: 16px;
        margin: auto;
        text-align: center;
      }
      li {
        width: 1px;
        height: 0.5rem;
        background: #666;
        margin: auto;
      }
    }
    .van-button--small {
      width: 8.8%;
      height: 50px;
      min-width: 0;
      border-radius: 5px;
      margin: 5px 2px;
      box-shadow: 5px 5px 5px #aaa;
    }
    .van-button--small:nth-child(1) {
      margin-bottom: 5px;
    }
    .van-button--small:nth-child(21) {
      margin-left: 5%;
    }
    .van-button--small:nth-child(30) {
      margin-left: 10%;
    }
    .van-button--small:last-child {
      width: 13%;
    }
  }
}
</style>
<style lang="less">
.displayNone {
  display: none !important;
}
</style>
<style>
.plate_number_box .van-button--small {
  font-size: 16px;
}
.plate_chinese_box .van-button--small {
  font-size: 16px;
}
</style>