import request from '@/libs/request'

const order = {

  dingdanlist(params) {//查询订单列表status -1:全部 0:待付款 1:待收货 2:待安装
    return request({
      url: '/vehicle/diy/mall/order/list',
      method: 'get',
      params
    })
  },
  dingdandetails(params) {//查询订单详情
    return request({
      url: '/vehicle/diy/mall/order/details',
      method: 'get',
      params
    })
  },
  quxiaoorder(params) {//取消订单
    return request({
      url: `/vehicle/diy/mall/cancel/order/${params.id}/${params.type}`,
      method: 'put',
      params
    })
  },
  querenorder(params) {//确认收货
    return request({
      url: `/vehicle/diy/mall/update/order/${params.id}`,
      method: 'put',
      params
    })
  },
  shanchuorder(params) {//删除取消订单
    return request({
      url: `/vehicle/diy/mall/delete/order/${params.id}/${params.type}`,
      method: 'delete',
      params
    })
  },
  huiyuanlist(params) {//查询h会员套餐
    return request({
      url: "/vehicle/system/gradeType/online/list",
      method: 'get',
      params
    })
  },
  taocanlist(params) {//查询所有线上套餐信息
    return request({
      url: "/vehicle/member/combo/online/list",
      method: 'get',
      params
    })
  },
  goumaivip(data) {//套餐会员卡储值卡线上购买
    return request({
      url: '/vehicle/diy/mall/add/combo/vip',
      method: 'post',
      data
    })
  },
  username(params) {//获取个人信息
    return request({
      url: '/vehicle/diy/mall/user',
      method: 'get',
      params
    })
  },
  onlinegetMemberStoreRecord(params) {//线上储值金消费记录
    return request({
      url: '/vehicle/customer/member/online/getMemberStoreRecord',
      method: 'get',
      params
    })
  },
  getMemberStoreRecord(params) {//储值卡充值消费记录
    return request({
      url: '/vehicle/customer/member/getMemberStoreRecord',
      method: 'get',
      params
    })
  },
  weixiulist(params) {//商城车看维修记录
    return request({
      url: '/vehicle/diy/mall/fix/list',
      method: 'get',
      params
    })
  },

  station(params) {//工位情况
    return request({
      url: '/vehicle/appointment/station/new',
      method: 'get',
      params
    })
  },
  yuyuelist(params) {//车主预约记录
    return request({
      url: '/vehicle/appointment/mall/list',
      method: 'get',
      params
    })
  },
  yuyuexianqin(params) {//查询类目下的商品
    return request({
      url: `/vehicle/appointment/details/${params.id}`,
      method: 'get',
      params
    })
  },
  yuyuexiugai(params) {//修改预约状态 status:0 取消预约 1:同意预约 2:修改预约时间并同意
    return request({
      url: '/vehicle/appointment/update',
      method: 'post',
      params
    })
  },
  QXappointment(params) {//线上订单取消预约
    return request({
      url: '/vehicle/appointment/cancel/appointment',
      method: 'post',
      params
    })
  },
  yuyuexinzen(data) {//新增预约记录
    return request({
      url: '/vehicle/appointment/save',
      method: 'post',
      data
    })
  },
  yuyuenew(params) {//预约查询订单详情
    return request({
      url: 'vehicle/diy/mall/order/details/new',
      method: 'get',
      params
    })
  },
  count(params) {//预约查询订单详情
    return request({
      url: '/vehicle/diy/mall/count',
      method: 'get',
      params
    })
  },
  onlinePay(params) {//取消二维码支付
    return request({
      url: '/vehicle/order/cancel/onlinePay',
      method: 'post',
      params
    })
  },
  myCoupon(params) {//查询我的优惠券列表
    return request({
      url: '/vehicle/coupon/myCoupon',
      method: 'get',
      params
    })
  },
  myCouponnew(params) {//查询我的优惠券列表
    return request({
      url: '/vehicle/coupon/myCoupon/new',
      method: 'get',
      params
    })
  },
  myUnCoupon(params) {//查询未领取优惠券
    return request({
      url: '/vehicle/coupon/myUnCoupon',
      method: 'get',
      params
    })
  },
  receive(params) {//领取优惠券
    return request({
      url: `/vehicle/coupon/receive/${params.id}`,
      method: 'post',
      params
    })
  },

  limit(params) {//领取限时优惠券
    return request({
      url: '/vehicle/coupon/receive/limit',
      method: 'post',
      params
    })
  },
  exchange(params) {//三方兑换奖品
    return request({
      url: '/vehicle/market/tripartite/exchange',
      method: 'post',
      params
    })
  },

  choujianglist(params) {//查看奖项列表 0:下架 1:上架
    return request({
      url: '/vehicle/luck/list',
      method: 'get',
      params
    })
  },
  choujiangdraw(params) {//抽奖
    return request({
      url: '/vehicle/luck/draw',
      method: 'get',
      params
    })
  },
  drawlist(params) {//查看我的奖品列表
    return request({
      url: '/vehicle/luck/prize/list',
      method: 'get',
      params
    })
  },
  getMyActivityTakeRecord(params) {
    return request({
      url: '/vehicle/activity/getMyActivityTakeRecord',
      method: 'get',
      params
    })
  },
  linquprize(params) {//领取奖品/发放奖品
    return request({
      url: `/vehicle/luck/get/prize/${params.id}`,
      method: 'post',
      params
    })
  },
  //查询已结算工单详情（客户）
  getSettlementOrder(params) {
    return request({
      url: '/vehicle/order/getSettlementOrder',
      method: 'get',
      params
    })
  },
  //查询所有线上套餐详情
  tancandetails(params) {
    return request({
      url: '/vehicle/member/combo/online/details',
      method: 'get',
      params
    })
  },
  //储值卡模板列表
  chizhikalist(params) {
    return request({
      url: '/vehicle/customer/member/model/list',
      method: 'get',
      params
    })
  },
  //查询我的拓客券
  tokercoupon(params) {
    return request({
      url: '/vehicle/coupon/get/customerId/toker',
      method: 'get',
      params
    })
  },
  coupontransfer(params) {//转赠拓客券
    return request({
      url: '/vehicle/coupon/transfer',
      method: 'post',
      params
    })
  },
  couponsell(params) {//生成出售拓客券的支付二维码
    return request({
      url: '/vehicle/coupon/sell',
      method: 'post',
      params
    })
  },
  //券商城接口(分类查询优惠券)
  couponmall(params) {
    return request({
      url: '/vehicle/coupon/mall',
      method: 'get',
      params
    })
  },
    //查询车牌是新客户还是老客户
    newold(params) {
      return request({
        url: '/vehicle/customer/car/new/old',
        method: 'get',
        params
      })
    },
  buycoupon(data) {//购买卡券
    return request({
      url: '/vehicle/coupon/buy/coupon',
      method: 'post',
      data
    })
  },
  couponuse(params) {//使用优惠券
    return request({
      url: '/vehicle/coupon/use',
      method: 'post',
      params
    })
  },
    //查询连锁门店储值卡列表
    storelist(params) {
      return request({
        url: '/vehicle/customer/member/member/store/list',
        method: 'get',
        params
      })
    },
}
export default order
